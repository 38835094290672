<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <!-- headerPicture -->
    <div class="heardePicture">
      <div class="heardePicture_text">
        <h1>大数据融合平台</h1>
        <p>BIG DATA INTEGRATION PLATFORM</p>
        <p>
          以用户为中心，全面构建自主可控的公共交通大数据产业链、价值链和生态链，助力用户深度融合移动互联网，运用大数据分析能力推进公共交通决策科学化、治理精准化、公共服务高效化。
        </p>
      </div>
      <div class="heardePicture_menu">
        <ul>
          <li @click="selectPage_1">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据云平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据云平台-选中.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_2">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/安全平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/安全平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_3">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_4">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/APP出行平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/APP出行平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_5">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/充电桩-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/充电桩.png" alt=""
            /></span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="securityPlatform">
      <section id="about-us" class="about-us">
        <!-- 标题 -->
        <h2 class="title1">大数据融合平台</h2>
        <!-- 补充说明区域 -->
        <p class="intro">用实时全量的城市数据资源全局优化城市公共资源</p>
      </section>
    </div>
    <!-- 线路OD -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="../../assets/image/产品服务/大数据决策云平台/线路OD-配图.png"
          alt=""
        />
        <div class="realTime_title">
          <h2>线路OD</h2>
          <!-- <img src="../assets/image/产品服务/安全平台/标题-bg.png" alt=""> -->
        </div>
        <p class="realTime_content">
          实时展示线路OD状态,线路OD可反应乘客的出行特征及方式,可根据线路OD对密集点乘车进行分流,优化乘车体验,根据客流量少的站点减少排班班次,最大化利用车队资源
        </p>
      </div>
    </div>
    <!-- 站点OD -->
    <div class="securityAccident">
      <div class="securityAccident_title">
        <h2>站点OD</h2>
      </div>
      <p class="securityAccident_content">
        按照乘客上车位置、下车站点、乘车方向、进行OD推导,根据OD反馈数据,调整站点覆盖范围以及站点重叠性,提高有效出行率
      </p>
      <img
        src="../../assets/image/产品服务/大数据决策云平台/站点OD-配图.png"
        alt=""
      />
    </div>
    <!-- 乘客画像 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="../../assets/image/产品服务/大数据决策云平台/乘客画像-配图.png"
          alt=""
        />
        <div class="realTime_title">
          <h2>乘客画像</h2>
        </div>
        <p class="realTime_content">
          从不同维度对城市的公交客流量进行事件和空间的统计分析,对不同群体乘客的出行规律进行分析,转化为可视化报表,为公交规划和公交管理决策等工作提供精确、量化依据
        </p>
      </div>
    </div>
    <!-- 智能排班 -->
    <div class="securityAccident">
      <div class="securityAccident_title">
        <h2>智能排班</h2>
        <!-- <img src="../assets/image/产品服务/安全平台/标题-bg.png" alt=""> -->
      </div>
      <p class="securityAccident_content">
        通过对公交客流大数据的积累分析,将人工智能算法结合公交大数据,并综合客流时间、空间数据及线路车辆周转时间预测值,自动生成科学的日计划排班计划,切实提高高峰期公交车载客能力、缩短乘客候车时长,改善乘客出行体验
      </p>
      <img
        src="../../assets/image/产品服务/大数据决策云平台/智能排班-配图.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chooseItem: '',
      asideMenuList: [{}]
    }
  },
  methods: {
    selectPage_1() {
      this.$router.push('/productService')
      window.scrollTo(0, 0)
    },
    selectPage_2() {
      this.$router.push('/securityPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_3() {
      this.$router.push('/monitoringPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_4() {
      this.$router.push('/travelApp')
      window.scrollTo(0, 0)
    },
    selectPage_5() {
      this.$router.push('/chargingPile')
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="less" scoped>
.heardePicture {
  position: relative;
  width: 100%;
  height: 1000px;
  background: url('../../assets/image/产品服务/大数据决策云平台/banner.png');
  .heardePicture_text {
    position: absolute;
    top: 270px;
    left: 285px;
    width: 480px;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    p {
      font-size: 24px;
      margin-bottom: 70px;
      color: #fefefe;
    }
  }

  .heardePicture_menu {
    position: fixed;
    z-index: 999;
    top: 222px;
    right: 100px;
    ul {
      width: 124px;
      li {
        width: 124px;
        text-align: center;
      }
    }
  }
}
.securityPlatform {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 所有section都按grid布局 */
section {
  text-align: center;
  margin: 50px 30% 0;
  width: 66%;
  height: 900px;
  background: url('../../assets/image/产品服务/大数据决策云平台/图.png') no-repeat;
}

/* 区域大标题 */
.title1 {
  margin-top: 100px;
  /* justify-self: center; */
  /* position: relative; */
  font-size: 36px;
  color: var(--text-color-darker);
}
.intro {
  font-size: 24px;
  color: #999;
}

.realTime {
  background: #f3f3f8;
  padding: 80px 0;
  .realTime_box {
    display: grid;
    /* 规划栅格，图标占两行，标题和内容各占一行 */
    grid-template-areas:
      'img title'
      'img content';
    /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
    grid-template-columns: 900px 1fr;
    /* 三行 */
    grid-template-rows: 1fr 2fr;
    margin-left: 200px;
    .realTimeP {
      grid-area: img;
    }
    .realTime_title {
      h2 {
        font-size: 36px;
        color: #111;
        margin-top: 66px;
      }
      grid-area: title;
      background: url('../../assets/image/产品服务/大数据决策云平台/标题-bg.png')
        no-repeat;
    }
    .realTime_content {
      width: 537px;
      grid-area: content;
      font-size: 24px;
      color: #666;
      line-height: 40px;
    }
  }
}
.securityAccident {
  display: grid;
  /* 规划栅格，图标占两行，标题和内容各占一行 */
  grid-template-areas:
    'title img '
    'content img ';
  /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
  grid-template-columns: 700px 1fr;
  /* 三行 */
  grid-template-rows: 1fr 2fr;
  margin-left: 250px;
  margin-top: 160px;
  img {
    grid-area: img;
  }
  .securityAccident_title {
    h2 {
      font-size: 36px;
      color: #111;
      margin-top: 66px;
    }
    grid-area: title;
    background: url('../../assets/image/产品服务/大数据决策云平台/标题-bg.png')
      no-repeat;
  }
  .securityAccident_content {
    width: 537px;
    grid-area: content;
    font-size: 24px;
    color: #666;
    line-height: 40px;
  }
}
</style>
